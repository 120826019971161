.hamburger {
  position: fixed;
  right: rem-calc(20);
  top: rem-calc(20);
  z-index: 999;
  @include breakpoint(large) {
    right: rem-calc(30);
    top: rem-calc(30);
  }
  button {
    position: relative;
    width: rem-calc(52);
    height: rem-calc(46);
    background-color: $red;
    display: block;
    border-radius: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: 0 0 8px 0 rgba($black,0.3);
    &:hover {
      background-color: darken($red,15%);
    }
    &:focus {
      background-color: $red;
    }
  }
  span {
    display: block;
    background-color: $white;
    position: absolute;
    width: rem-calc(34);
    left: rem-calc(8);
    height: rem-calc(3);
    top: rem-calc(8);
    opacity: 1;
    transition: all .3s ease-in-out;
    + span {
      top: rem-calc(16);
      + span {
        top: rem-calc(24);
        + span {
          top: rem-calc(32);
        }
      }
    }
  }
  &.is-active {
    span {
      transform: rotate(45deg);
      top: rem-calc(22);
      + span {
        opacity: 0;
        + span {
          opacity: 1;
          transform: rotate(-45deg);
          + span {
            opacity: 0;
          }
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}
