section {
  padding: rem-calc(50 0);
  @include breakpoint (large) {
    padding: rem-calc(100 0);
  }
  @include breakpoint (xlarge) {
    padding: rem-calc(120 0);
  }
  &#kontakt {
    background-color: $red;
    padding: 5em 0;
    color: $white;
    position: relative;
    z-index: 10;
  }
  &.nopadding {
    padding: 0;
  }
  &.nopadding-bottom {
    padding-bottom: 0;
  }
}
