table {
  margin-bottom: 3.5em;
  @include breakpoint(small only) {
    font-size: rem-calc(15);
  }
  tbody td,
  thead th {
    color: $red;
    padding: rem-calc(4 5);

    &:first-child {
      padding-left: 2px !important;
    }

    @include breakpoint(medium) {
      padding: rem-calc(4 8);
    }
  }
}

.table.dataTable {
  font-size: rem-calc(15);
  @include breakpoint(small only) {
    font-size: rem-calc(13);
  }
  line-height: 1;
  border-collapse: collapse;
  position: relative;
  margin-bottom: 3.5em;
  thead {
    tr {
      border-bottom: 1px solid $red;
      background-color: red;
      th {
        line-height: rem-calc(52);
        text-align: left;
        background: $white;
        &:nth-child(n+3) {
          text-align: right;
        }
        span {
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $black;
      &:focus {
        background-color: $ci-color;
        outline: none;
        td {
          color: $white;
          .svg-icon svg {
            fill: $white;
          }
        }
      }
      td {
        cursor: pointer;
        text-align: left;
        white-space: nowrap;
        padding: rem-calc(15 8);
        @include breakpoint(small only) {
          padding: rem-calc(10 5);
        }
        &:nth-child(n+3) {
          text-align: right;
        }
        .svg-icon {
          height: 1.4em;
          svg {
            bottom: 0;
            fill: $red;
            &:hover {
              fill: $black;
            }
          }
        }
      }
      &.sold td {
        opacity: 0.6;
      }
    }
  }
}

//scroll table horizontal
.dataTables_wrapper {
  //max-height: rem-calc(530);
  overflow: auto;
}

//hide arrows for sorting
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
}

//show border-bottom for sorting
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  span {
    border-bottom: 1px solid $red;
  }
}

.gewerbeImg {
  display: block;
  margin: 0 auto;
  margin-bottom: rem-calc(30);
  @include breakpoint(large) {
    padding-top: rem-calc(50);
  }
}

.table-head {
  text-transform: uppercase;
  font-size: rem-calc(20);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  @include breakpoint(medium) {
    font-size: rem-calc(41);
  }
  i {
    font-style: normal;
    order: 0;
    flex: 0 1 auto;
    align-self: flex-end;
    @include breakpoint(medium) {
    }
  }
  span {
    font-size: rem-calc(15);
    text-transform: none;
    order: 0;
    flex: 0 0 auto;
    align-self: flex-end;
    padding-bottom: 7px;
    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}

.table-sub {
  padding: 1em 0;
}
