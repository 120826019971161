main {
  font-size: rem-calc(17);
  @include breakpoint(large) {
    font-size: rem-calc(28);
  }
}

h1,
h2 {
  text-align: center;
  margin-bottom: 1em;
}

h3 {
  margin-bottom: 0.8em;
  line-height: 1.444;
}

p {
  margin-bottom: 1.4em;
}

.lead {
  color: #AA2E55CB;
  font-weight: bold;
  line-height: 1.2;
  @include breakpoint(large) {
    font-size: rem-calc(40);
  }
}

