.button {
  border-radius: 3em;
}

.btn-negative {
  border: 2px solid $white;
  background-color: $red;
  color: $white;
  &:hover {
    background-color: $white;
    color: $red;
  }
}


.circle {
  //border-radius: 50%;
  background: $red;
  width: rem-calc(120);
  height: rem-calc(120);
  transform: rotate(45deg);
  transition: all .2s ease-in-out;
  @include breakpoint (large) {
    font-size: rem-calc(24);
    width: rem-calc(276);
    height: rem-calc(276);
  }
  .circle-txt {
    text-align: center;
    position: relative;
    color: $white;
    font-size: rem-calc(17);
    font-weight: bold;
    top: 50%;
    transition: all .2s ease-in-out;
    transform: translateY(-50%) rotate(-45deg);
    @include breakpoint(large) {
      font-size: rem-calc(37);
      line-height: rem-calc(42);
    }
  }
  &.link {
    &:hover {
      transform: rotate(38deg);
      background-color: $orange;
      cursor: pointer;
      .circle-txt {
        //transform: translateY(-50%) rotate(-7deg);
      }
    }
    &:focus, &:active {
      color: $white;
    }
  }
}


.main-image, figure {
  .circle {
    position: absolute;
    bottom: rem-calc(-10);
    right: rem-calc(20);
    z-index:7;
    @include breakpoint (medium) {
      right: rem-calc(60);
    }
    @include breakpoint (large) {
      bottom: rem-calc(-50);
      right: rem-calc(100);
    }
    &--big {
      left: rem-calc(20);
      right: auto;
      @include breakpoint (medium) {
        left: rem-calc(60);
      }
      @include breakpoint (large) {
        left: rem-calc(100);
      }
    }
  }
  .arrow-down {
    position: absolute;
    bottom: rem-calc(20);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 6;
    opacity: 1;
    transition: all .2s ease-in-out;
    .icon {
      fill: $white;
    }
    &:hover {
      opacity: .6;
    }
  }
}

figure {
  .circle {
    top: rem-calc(20);
    bottom: auto;
    background: $orange;
    @include breakpoint (medium) {
      top: rem-calc(60);
    }
    @include breakpoint (large) {
      top: rem-calc(100);
    }
  }
}

.map-2-container {
  position: relative;
  .circle {
    position: absolute;
    bottom: rem-calc(-20);
    right: rem-calc(-10);
    @include breakpoint (medium) {
      bottom: rem-calc(20);
      right: rem-calc(10);
    }
  }
}

.main-image .arrowdown {
  position: absolute;
  bottom: 4vh;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  img {
    height: rem-calc(21);
    width: rem-calc(38);
  }
}
