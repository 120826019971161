.column-count {
  margin-bottom: 2em;
  @include breakpoint (medium) {
    column-count: 2;
    column-gap: 30px;
  }
}


.grid-container.fluid, .grid-container {
  padding-right: rem-calc(20);
  padding-left: rem-calc(20);
  @include breakpoint (large) {
    padding-right: rem-calc(40);
    padding-left: rem-calc(40);
  }
  @include breakpoint (xlarge) {
    padding-right: rem-calc(80);
    padding-left: rem-calc(80);
  }
}
