html.sr {
  .fadeinbottominterval, .fadeinbottom {
    visibility: hidden;
  }
}

.container-downloads {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0.5em;
  align-items: flex-start;  // needed so items shrink/display as inline-block
  

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.download {
  color: $red;
  text-decoration: underline;
  position: relative;
  padding-right: 1.2em;
  @include breakpoint(large) {
    font-size: rem-calc(22);
  }
  &::after {
    content: url(../images/Icon-File-Download.svg);
    width: auto;
    color: $black;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;

    @include breakpoint(large) {
      // for some reason just necessary when font-size (I at least it is connected to that) is bigger

      transform: translateY(.25em) scale(1.15);
    }
  }
  &+ .download {
    margin-top: 1em;
    @include breakpoint(medium) {
      margin-top: 0;
      margin-left: 2em;
    }
  }
}

.c-white {
  color: $white;
}
.c-blue {
  color: $blue;
}
.c-orange {
  color: $orange;
}