.combi-figure {
  position: relative;
  display: block;
  margin-bottom: rem-calc(10);
  &.two-columns-left {
    margin-right: rem-calc(5);
  }
  &.two-columns-right {
    margin-left: rem-calc(5);
  }
  img {
    width: 100%;
  }
}

.main-image {
  position: relative;

  img {
    object-fit: cover;
    height: 100vh;
    width: 100%;
  }

}

.small-img-right {
  margin-bottom: rem-calc(15);
  @include breakpoint(medium) {
    position: relative;
    right: -20px;
  }
  @include breakpoint(large) {
    right: -40px;
  }
  @include breakpoint(xlarge) {
    right: -100px;
  }
}

.small-img-left {
  margin-bottom: 2em;
  @include breakpoint(medium) {
    position: relative;
    left: -20px;
  }
  @include breakpoint(large) {
    left: -40px;
  }
  @include breakpoint(xlarge) {
    left: -100px;
  }
}

.place-desktop {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}

.place-mobile {
  display: block;
  @include breakpoint(medium) {
    display: none;
  }
}