.image-slider-cell {
  padding: 4em 1.5em;
  position: relative;
  @include breakpoint(xlarge) {
    padding: 0;
  }
}

#arrows > div {
  display: block;
  position: absolute;
  top: 50%;
  color: $white;
  cursor: pointer;
  transform: translateY(-50%);

  &.prev {
    left: 0;
    @include breakpoint(xlarge) {
      left: -3em;
    }
  }
  &.next {
    right: 0;
    @include breakpoint(xlarge) {
      right: -3em;
    }
  }
}

//icons
.svg-icon {
  height: 1em;
  width: 1em;
  @include breakpoint(xlarge) {
    height: 2em;
    width: 2em;
  }

  svg {
    fill: $black;
    &:hover {
      fill: $black;
    }
  }
}
