#kontakt {
  h1 {
    color: $white;
  }
}

.form-contact {
  label {
    font-size: rem-calc(16);
    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }
  }
  label[for="herr"] {
    margin-right: rem-calc(30);
  }
  .label-cell {
    display: block; //labels untereinander positionieren
  }
  .label-m-bottom {
    margin-bottom: rem-calc(15) !important;
    @include breakpoint(medium) {
      margin-bottom: rem-calc(10) !important;
    }
  }
  .m-bottom-small {
    margin-bottom: rem-calc(30);
  }

  span.error {
    position: relative;
    .error {
      position: absolute;
      width: 100vw;
      color: $black;
      top: 0.1em;
      font-size: 0.7rem;
      transform: translateY(-100%);
      @include breakpoint(medium) {
        font-size: 0.9rem;
      }
    }
    &.error_datenschutz .error {
      transform: translateY(0);
    }
  }
}

.form-contact {
  // space for error message
  // margin-bottom, because margin-top behaves different on frist than on others...
  input[type="text"],
  input[type="email"] {
    margin: 0;
    margin-bottom: 1.5em;
  }

  /* Hide the native radio and checkbox buttons */
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  /* override default styles from foundation */
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    margin-left: 0;
  }

  input[type="checkbox"] + label .checkbox,
  input[type="radio"] + label .radio {
    cursor: pointer;
    position: absolute;
  }

  /* text for label: (offset) margin-left, because of multiline-text! */
  input[type="checkbox"] + label .txt,
  input[type="radio"] + label .txt {
    display: block;
    margin-left: 45px;
    line-height: 1.3;
  }

  /* custom error for chekboxes */
  input[type="checkbox"] + label > .error .error {
    margin-left: 45px;
  }

  /* radio-buttons */
  input[type="radio"] + label .radio {
    background-color: $white;
    border: 2px solid $white;
    @include box(28px, 28px, true);
  }
  /* When the radio is checked (we could add a border, shadow, etc) */
  input[type="radio"]:checked + label .radio {
    &::before {
      content: "";
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background-color: $red;
      transform: translateY(-50%);
      @include box(14px, 14px, true); //half of span-width 28px
    }
  }

  /* checkbox-buttons */
  input[type="checkbox"] + label .checkbox {
    background-color: $white;
    // border: 2px solid $orange;
    width: 28px;
    height: 28px;
  }

  /* When the checkbox is checked (we could add a border, shadow, etc) */
  input[type="checkbox"]:checked + label .checkbox {
    &::before {
      content: "";
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      // color of check
      color: $red;

      top: 7px;
      width: 17px;
      height: 10px;
      border-left: 3px solid;
      border-bottom: 3px solid;
      transform: rotate(-45deg);
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

// container around data security question and submit button
// neded to have submit at bottom of parent container
.form-submit-container {
  display: flex;
  flex-direction: column;
  .c-submit {
    margin-top: auto;
  }
}

.form-block {
  margin-bottom: rem-calc(30);
}

::placeholder {
  opacity: 1;
}
