ul:not(.menu):not(.orbit-container) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}

ol.olcircle {
  list-style: none;
  max-height: 50%;
  // my-awesome-counter is like a css variable
  // will be overwritten by via style and counter-set on second ol
  counter-reset: my-awesome-counter;
  li {
    margin: 1em 0;
    padding-left: 3em;
    counter-increment: my-awesome-counter;
    position: relative;
    font-weight: 700;
    line-height: 2em;
    &:first-child {
      margin-top: 0;
    }
    &::before {
      content: counter(my-awesome-counter);
      color: $white;
      position: absolute;
      left: 0;
      width: 2em;
      height: 2em;
      top: 0;
      background: $blue;
      border-radius: 50%;
      text-align: center;
    }
  }
}
