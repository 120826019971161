.logo {
  padding: rem-calc(20);
  @include breakpoint(large) {
    padding: rem-calc(30);
  }
  a {
    display: inline-block;
    img {
      width: rem-calc(140);
      height: auto;
      @include breakpoint(large) {
        width: rem-calc(300);
      }
    }
  }
}


